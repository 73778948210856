import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import { fromJS, set } from 'immutable';

/* Containers */

/* Middleware */
import { tryGetExpertProfileFromToken } from '../../../../iframes/profile/middleware/profile';
import { trySaveCriteriaDetails } from "../middleware/prospects";
import { tryGetAdvisorProfile } from "../middleware/prospects";

/* Components */
import { LogoLoading } from "../../../../common/components/loading";
import { ButtonPrimary, ButtonTertiary } from "../../../../common/components/buttons";
import { colors } from "../../../../common/components/colors";
import {
    TextNoInput,
    TextareaInputNoCountWhite,
    TextareaInputNoCountWhiteSubtitle,
    DropdownInputWhite,
    TextInputWhite
} from "../../../../common/components/inputs";
import {FontBody16, FontBody21, FontSubtitle16} from "../../../../common/components/fonts";
import { BtnDiv } from "../../../components/advisorpreviewcard";
import { LoadingCenter } from "../../../shop/components/faqs";
import {ToolTipBody} from "../../../../common/components/messages";
import {InfoCircleOutlined} from "@ant-design/icons";
import {ToolTipContainer} from "../../certification/components/directoryform";


const ProfileMobile = styled.div`
    @media screen and (min-width: 1088px) {
        display: none;
    }
`
const Profile = styled.div`
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
    //height: 50vh;
    & > div {
        overflow-y: auto;
        overflow-x: hidden;
    }
    @media screen and (max-width: 1440px) {
        & > div > div:first-child {
          width: 450px;
          margin: 0;
        }
        & > div > div:last-child {
          max-width: 100%;
        }
    }
    @media screen and (max-width: 1088px) {
        & > div > div:first-child {
          width: 100%;
        }
        & > div > div:last-child {
          width: 100%;
        }
    }
`
const MobileContainerScheduler = styled.div`
    //height: 50vh;
    background-color: ${colors.white};
    overflow-y: auto;
    color: ${colors.primary100};
`
const Footer = styled.div`
    display: flex;
    justify-content: center;
    padding: 25px 0;
    width: 100%;
    background-color: ${colors.backgroundColor3};
    border-radius: 0 0 20px 20px;
`;
const FitContent = styled.div`
    width: fit-content;
    margin: 0 auto;
`;
const ProfileDesktop = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    @media screen and (max-width: 1088px) {
        display: none;
    }
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    //height: 50vh;
    & > div {
        overflow-y: auto;
        overflow-x: hidden;
    }
    @media screen and (max-width: 1440px) {
        & > div > div:first-child {
          width: 450px;
          margin: 0;
        }
        & > div > div:last-child {
          max-width: 400px;
        }
    }
    @media screen and (max-width: 1088px) {
        & > div > div:first-child {
          width: 100%;
        }
        & > div > div:last-child {
          width: 100%;
        }
    }
`
const FlexWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 80px 10px 35px 10px;
    justify-content: space-evenly;
    flex-direction: row-reverse;
    overflow: scroll;
    margin-top: -40px;
    background-color: rgba(57, 73, 80, 0.15);
    height: auto;
`
const ContainerProfile = styled.div`
    max-width: 600px;
    width: 55%;
    margin: 10px 10px;
`
const ContainerScheduler = styled.div`
    width: 500px;
    margin: 10px 10px;
    padding: 10px 10px 15px 10px;
    background-color: white;
    border-radius: 25px;
    height: fit-content;
    color: ${colors.primary100};
`
const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;
const Section = styled(FlexCol)`
    gap: 20px;
`;
const SectionPadding = styled(Section)`
    padding: 70px 40px 100px;
    margin: -25px 0;
    background-color: ${colors.backgroundColor1};
`;

const Checkbox = styled.input`
    height: 18px;
    width: 18px;
    flex: none;
    accent-color: ${colors.action100};
`;

const CheckboxContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 10px;
    @media screen and (max-width: 750px) {
        grid-template-columns: 1fr 1fr;
    }
`;

const StateCheckbox = styled.div`
    border: 1px solid ${colors.primary30};
    border-radius: 4px;
    padding: 10px 20px;
`;

const CheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    white-space: nowrap;
    @media screen and (max-width: 1440px) {
        white-space: normal;
    }
`;

const Error = styled.div`
    margin-bottom: 20px;
`;

export default function FirmMarketingCriteriaQuestions() {
    const dispatch = useDispatch();
    const [assets, setAssets] = useState("");
    const [location, setLocation] = useState([]);
    const [goodClient, setGoodClient] = useState("");
    const [badClient, setBadClient] = useState("");
    const [fees, setFees] = useState([]);
    const [addFees, setAddFees] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [checkedStates, setCheckedStates] = React.useState(Array(54).fill(false));
    const [feeTypes, setFeeTypes] = React.useState(Array(5).fill(false));
    const [error, setError] = useState("");
    const feeStructure = ["Flat fee Hourly", "Flat fee Monthly", "Flat fee Yearly", "% Assets Under Management", "Flexible"]
    const states = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY", "PR", "VI", "GU"];
    const [toolTipSchedulingLink, setToolTipSchedulingLink] = useState(false);
    const [schedulingLink, setSchedulingLink] = useState("")
    useEffect(() => {
        init();
    }, []);
    const assetsNumber = assets ? Number(assets.replace(/[\$,]/g, '')) : -1;
    const assetOptions = [
        {value: '0', label: 'Less than $100k'},
        {value: '100000', label: 'Minimum $100K'},
        {value: '250000', label: 'Minimum $250K'},
        {value: '500000', label: 'Minimum $500K'},
        {value: '1000000', label: 'Minimum $1M'},
        {value: '5000000', label: 'Minimum $5M'}
    ];

    const handleCheckAll = async (event) => {
        let newCheckedStates = Array(54).fill(event.target.checked);
        setCheckedStates(newCheckedStates);
        let newStates = states.filter((_, index) => newCheckedStates[index]);
        setLocation(newStates);
    };

    const handleStateCheck = (index) => async (event) => {
        let newCheckedStates = [...checkedStates];
        newCheckedStates[index] = event.target.checked;
        setCheckedStates(newCheckedStates);
        let newStates = states.filter((_, index) => newCheckedStates[index]);
        setLocation(newStates);
    };

    const handleFeeCheck = (index) => async (event) => {
        let newFee = [...feeTypes];
        newFee[index] = event.target.checked;
        setFeeTypes(newFee);
        let feeIndices = newFee.map((value, index) => value ? index + 1 : false).filter(value => value !== false);
        setFees(feeIndices);
    };

    const profileFeeCheck = (index) => {
        const feeArray = Array.from({ length: 5 }, (_, i) => index.includes(i + 1));
        setFeeTypes(feeArray);
        let feeIndices = feeArray.map((value, index) => value ? index + 1 : false).filter(value => value !== false);
        setFees(feeIndices);
    }

    const profileStateCheck = (index) => {
        const stateIndices = index.map(state => states.indexOf(state));
        const stateArray = Array.from({ length: 54 }, (_, index) => stateIndices.includes(index));
        setCheckedStates(stateArray);
        let newStates = states.filter((_, index) => stateArray[index]);
        setLocation(newStates);
    };

    const handleSave = async () => {
        setIsLoading(true);
        if (assetsNumber === "" || assetsNumber === null || assetsNumber === undefined || assetsNumber === -1) {
            setError("Please enter minimum assets");
            return;
        }
        if (location === undefined || location.length === 0 || location === "" || location === null) {
            setError("Please select at least one state");
            return;
        }
        if (goodClient === "" || goodClient === null || goodClient === undefined) {
            setError("Please describe your ideal client");
            return;
        }
        if (fees === undefined || fees.length === 0 || fees === "" || fees === null) {
            setError("Please select at least one fee structure");
            return;
        }
        else {
            await dispatch(trySaveCriteriaDetails({
                "minimumInvestableAssets": assetsNumber,
                "statesCovered": location,
                "goodClientFit": goodClient,
                "badClientFit": badClient,
                "feeTypes": fees,
                "feeDescription": addFees,
                "schedulingLink": schedulingLink
            }));
            setError("");
        }
        setIsLoading(false);
    };

    const init = async () => {
        setIsLoading(true);
        const profile = await dispatch(tryGetAdvisorProfile());
        setAssets(profile?.minimumInvestableAssets !== null && profile?.minimumInvestableAssets !== undefined ? profile.minimumInvestableAssets.toString() : "");
        setGoodClient(profile?.goodClientFit ? profile.goodClientFit : "");
        setBadClient(profile?.badClientFit ? profile.badClientFit : "");
        profileStateCheck(profile?.statesCovered ? profile.statesCovered : []);
        profileFeeCheck(profile?.feeTypes ? profile.feeTypes : []);
        setAddFees(profile?.feeDescription ? profile.feeDescription : "");
        setSchedulingLink(profile?.schedulingLink ? profile.schedulingLink : "")
        setIsLoading(false);
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if(prevProps.coachId !== this.props.coachId) {
    //         return this.init();
    //     }
    // }

    // init = async () => {
    //     const profile = await this.props.tryGetExpertProfileFromToken(this.props.coachId);
    //     await this.setState({
    //         "profile": fromJS(profile),
    //         "isLoading": false
    //     });

    // };


    if (isLoading) {
        return (<LoadingCenter><LogoLoading /></LoadingCenter>)
    }
    return (
        <>
            <TextNoInput title={"Which states and territories are you able/willing to work in"} valid={(location && location.length > 0)} warning={"Required"} />
            <StateCheckbox>
                <CheckboxLabel style={{ marginBottom: '20px' }}>
                    <Checkbox type="checkbox" onChange={handleCheckAll} />
                    <FontBody16> Check All </FontBody16>
                </CheckboxLabel>
                <CheckboxContainer>
                    {states.map((state, index) => (
                        <CheckboxLabel key={index} >
                            <Checkbox
                                type="checkbox"
                                value={state}
                                checked={checkedStates[index]}
                                onChange={handleStateCheck(index)}
                            />
                            <FontBody16> {state} </FontBody16>
                        </CheckboxLabel>
                    ))}
                </CheckboxContainer>
            </StateCheckbox>
            <TextNoInput title={"Describe your fee structure: "} valid={(fees && fees.length > 0)} warning={"Required"} />
            <StateCheckbox>
                <CheckboxContainer>
                    {feeStructure.map((state, index) => (
                        <CheckboxLabel key={index} style={{marginRight: '10px'}}>
                            <Checkbox
                                type="checkbox"
                                value={state}
                                checked={feeTypes[index]}
                                onChange={handleFeeCheck(index)}
                            />
                            <FontBody16> {state} </FontBody16>
                        </CheckboxLabel>
                    ))}
                </CheckboxContainer>
            </StateCheckbox>
            <TextareaInputNoCountWhiteSubtitle title={"Additional information on fees "} valid={(addFees !== "")} id={"addFees"} onChange={(e) => setAddFees(e.target.value)} value={addFees} minRows={5} minLength={0} subtitle={"Please provide any additional information on your fees that you think is important for clients to know"} />
            <DropdownInputWhite 
                id={"assets"}
                title={"What are your required minimum assets?"}
                options={assetOptions}
                valid={(assets !== null && assets !== "")}
                value={assets}
                onChange={(e) => setAssets(e.value)}
                warning={"Required"}
            />
            <TextareaInputNoCountWhiteSubtitle title={"Who is your ideal client?"} valid={(goodClient !== "")} id={"goodClient"} onChange={(e) => setGoodClient(e.target.value)} value={goodClient} minRows={5} minLength={0} warning={"Required"} subtitle={"Describe their demographics, profession, goals, life journey and other relevant information."} />
            <TextareaInputNoCountWhite title={"Which clients do you feel are not a fit for your practice? "} valid={(badClient !== "")} id={"badClient"} onChange={(e) => setBadClient(e.target.value)} value={badClient} minRows={5} minLength={0} />
            <ToolTipContainer>
                <ToolTipBody
                    show={toolTipSchedulingLink}
                    onMouseLeave={() => setToolTipSchedulingLink(false)}

                >
                    <FontSubtitle16>*For Willow internal purposes only</FontSubtitle16>
                </ToolTipBody>

                <TextInputWhite
                    title={
                        <span>
                    Input a link to your schedule{" "}
                            <span style={{ width: "50px", height: "50px" }} onMouseEnter={() => setToolTipSchedulingLink(true)}>
                    <InfoCircleOutlined />{" "}
                    </span>
                    </span>}
                    valid={schedulingLink !== ""}
                    id={"schedulingLink"}
                    onChange={(e) => setSchedulingLink(e.target.value)}
                    value={schedulingLink}
                />
            </ToolTipContainer>
            <Error>
                <FontBody16 style={{ color: `${colors.stoplightStop100}` }}>{error}</FontBody16>
            </Error>
            <BtnDiv onClick={handleSave}><ButtonPrimary canSubmit={true} label={"Save"} /></BtnDiv>
        </>
    )
}

// const mapDispatchToProps = dispatch => ({
//     tryGetExpertProfileFromToken: (id) => dispatch(tryGetExpertProfileFromToken(id)),
// });

// export default connect(null, mapDispatchToProps)(FirmMarketingCriteriaQuestions);